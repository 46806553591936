import React, {useState} from 'react';
import './AdventurePage.css'
import './App.css';
import './button.css';
import './CharacterSelection.css';
import raw from './prompt.txt';
function App() {

const initialTotal = 10;
const [prompt, setPrompt] = useState('');
const [currentScreen, setCurrentScreen] = useState('home');
const [inputValue, setInputValue] = useState('');
const [chatHistory, setChatHistory] = useState([]);
const [strength, setStrength] = useState(5);
const [dexterity, setDexterity] = useState(5);
const [intelligence, setIntelligence] = useState(5);
const [constitution, setConstitution] = useState(5);
const [wisdom, setWisdom] = useState(5);
const [charisma, setCharisma] = useState(5);
const [total, setTotal] = useState(initialTotal);
const [archetype, setArchetype] = useState('Warrior');
const [currentResponse, setCurrentResponse] = useState('');
const [previousResponse, setPreviousResponse] = useState('');
const [transitioning, setTransitioning] = useState(false);
const [attrRemaining, setAttrRemaining] = useState(false);
const [charLimit, setCharLimit] = useState(200);
const [newResponse, setNewResponse] = useState(true);
const [currentBackground, setCurrentBackground] = useState('castle fade-in');
const [hasResponded, setHasResponded] = useState(false);

fetch(raw)
.then(r => r.text()).then(text => {
  setPrompt(text);
});

const handleScreenChange = (newScreen) => {
  setTransitioning(true);
  setTimeout(() => {
    setCurrentScreen(newScreen);
    setTransitioning(false); // Start the fade-in transition
  }, 2000); // Match the duration of the fade-out animation

}

const startAdventure = () => {
  handleScreenChange('characterCreation');
};
const beginGame = () => {
  if(total === 0) {
    
    const firstMessage = prompt + " Archetype: " + archetype +  ". Strength: " + strength + " Dexterity: "
     + dexterity + " Intelligence: " + intelligence + " Wisdom: " + wisdom + 
     " Charisma: " + charisma + " Constitution: " + constitution;
    console.log(firstMessage);
    const updatedChatHistory = [...chatHistory, {sender: 'user', message: firstMessage}];
    setChatHistory(updatedChatHistory);
    sendMessageToBackend(updatedChatHistory);
    setAttrRemaining(false);
    setTransitioning(true);
    setTimeout(() => {
      setCurrentScreen('adventure');
      setTransitioning(false); 
    }, 2000); 
  } else {
    setAttrRemaining(true);
  }
  

}

const incrementAttribute = (attributeSetter, attributeValue) => {
  if (total > 0 && attributeValue < 20) {
    attributeSetter(attributeValue + 1);
    setTotal(total - 1);
  }
};

const decrementAttribute = (attributeSetter, attributeValue) => {
  if (attributeValue > 0) {
    attributeSetter(attributeValue - 1);
    setTotal(total + 1);
  }
};


const chooseArchetype = (type) => {
  setArchetype(type);
};

const handleInputChange = (event) => {
  if (event.target.value.length <= charLimit) {
    setInputValue(event.target.value);
  }
};

const handleKeyDown = (event) => {
  if (event.key === 'Enter') {
    const updatedChatHistory = [...chatHistory, {sender: 'user', message: inputValue}];
    setChatHistory(updatedChatHistory);
    sendMessageToBackend(updatedChatHistory);
    setCurrentResponse(inputValue);
    setInputValue(''); 
    setNewResponse(false);
  }
};

const sendMessageToBackend = (message) => {
  fetch('/api', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ chatHistory: message }),
  })
  .then(response => response.json())
  .then(data => {
    //handle response
    console.log('Message sent successfully:', data);
    const updatedChatHistory = [...message, {sender: 'gemini', message: data}]
    setChatHistory(updatedChatHistory);
    setPreviousResponse(data);
    setNewResponse(true);
    

    if(!hasResponded) {
      if (data.includes('woodland castle')) {
        setCurrentBackground('castle');
      }else if(data.includes('evil castle')) {
        setCurrentBackground('evilcastle');
      }else if(data.includes('mountain path')) {
        setCurrentBackground('mountainpath');
      }else if(data.includes('tavern')) {
        setCurrentBackground('tavern');
      }else if(data.includes('desolate battlefield')) {
        setCurrentBackground('battlefield');
      }else if(data.includes('dragon')) {
        setCurrentBackground('dragon');
      }
    }
    setHasResponded(true);

    if (data === '') {
      setChatHistory('Too many people are using the website due to the Gemini API limit, please try again later!');
      setPreviousResponse('Too many people are using the website due to the Gemini API limit, please try again later!');
    }

  })
  .catch((error) => {
    console.error('Error sending message:', error);
  });
};


  return (
    <div className={transitioning ? 'fade-out': 'fade-in'}>
      {currentScreen === 'home' && (
        <div className="body">
          <h1 className="slideInTop">Tales of Bard
          </h1>
          <button className= "btn slideInBottom" onClick={startAdventure}>START</button>
          <h1 className="about">About</h1>
          <div className="desc">Tales of Bard is a web app 
            I developed using React and Node.js. It uses Google's 
            Gemini API to act as a dungeon master in an abstract game 
            of Dungeons and Dragons.
            The images you see were sourced from Pixabay, 
            then I converted them to video using Stable Diffusions Img2Vid model. 
            The most challenging thing about this project was deployment, 
            as the hosting service I used did not support the version of Node I 
            used to create the server. <p></p>My portfolio: <p></p>
            <a href="https://github.com/KingCubic">github.com/KingCubic
            </a>  <p></p>LinkedIn: <p></p><a href="https://www.linkedin.com/in/bostonwoods/">
            linkedin.com/in/bostonwoods</a> <p></p> Email: <p></p> BostonWoods.dev@protonmail.com</div>
          
        </div>
      )}

      {currentScreen === 'characterCreation' && (
        <div className="cc">
          <div>
          {archetype === 'Warrior' && (
            <div className='warrior fade-in'>
              

            </div>
          )}

          {archetype === 'Mage' && (
            <div className='mage fade-in'>
              

            </div>
          )}

          {archetype === 'Rogue' && (
            <div className='rogue fade-in'>
              

            </div>
          )}
          </div>




          <div className="repos">
            <h2 className="slideInSide"> </h2>

            {/* Roll Stats */}
            {/* Strength */}
            <div className="slideInSide cca">


              {/* Choose Archetype */}
              <div>
                <h3>Choose Your Archetype:</h3>
                <button className='btnArch' onClick={() => chooseArchetype('Warrior')}>Warrior</button>
                <button className='btnArch' onClick={() => chooseArchetype('Mage')}>Mage</button>
                <button className='btnArch' onClick={() => chooseArchetype('Rogue')}>Rogue</button>
                <p>Selected Archetype: {archetype}</p>
              </div>

              <div>
              <button className='btnPlus' onClick={() => incrementAttribute(setStrength, strength)}>+</button>
              <button  className='btnPlus' onClick={() => decrementAttribute(setStrength, strength)}>-</button>
                <span>  Strength: {strength}</span>

              </div>

              {/* Dexterity */}
              <div>
              <button  className='btnPlus' onClick={() => incrementAttribute(setDexterity, dexterity)}>+</button>
              <button  className='btnPlus' onClick={() => decrementAttribute(setDexterity, dexterity)}>-</button>
                <span>  Dexterity: {dexterity}</span>

              </div>

              {/* Intelligence */}
              <div>
              <button  className='btnPlus' onClick={() => incrementAttribute(setIntelligence, intelligence)}>+</button>
              <button  className='btnPlus' onClick={() => decrementAttribute(setIntelligence, intelligence)}>-</button>
                <span>  Intelligence: {intelligence}</span>

              </div>

              <div>
              <button  className='btnPlus' onClick={() => incrementAttribute(setConstitution, constitution)}>+</button>
              <button  className='btnPlus' onClick={() => decrementAttribute(setConstitution, constitution)}>-</button>
                <span>  Constitution: {constitution}</span>

              </div>
              <div>
              <button  className='btnPlus' onClick={() => incrementAttribute(setCharisma, charisma)}>+</button>
              <button  className='btnPlus' onClick={() => decrementAttribute(setCharisma, charisma)}>-</button>
                <span>  Charisma: {charisma}</span>

              </div>
              <div>
              <button  className='btnPlus' onClick={() => incrementAttribute(setWisdom, wisdom)}>+</button>
              <button  className='btnPlus' onClick={() => decrementAttribute(setWisdom, wisdom)}>-</button>
                <span>  Wisdom: {wisdom}</span>

              </div>

              <div>
                <span className='vspace'> Unused Attribute Points: {total}</span>
              </div>
            </div>


            <button className='start btn' onClick={() => beginGame()}>Start!</button>
            {attrRemaining === true && (
              <div className='warning'> You need to utilize your remaining Attributes! </div>
            )}
            
          </div>



        </div>
        
      )}


      {currentScreen === 'adventure' && (
        <div className={currentBackground} >
          {newResponse === true && (
            <div className='geminiResponse slideInResponse'>{previousResponse}</div>
          )}
          

          {currentResponse !== '' && ( 
            <div className='playerOutput'>{currentResponse}</div>
          )}
          
          <div className="inputContainer">
            
            <textarea
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Type something..."
              className='inputBox'
              maxLength={charLimit}
            />

            <div className="charCounter">
              {inputValue.length}/{charLimit}
            </div>
          </div>

        </div>
      )}



    </div>
  )
}

/*{chatHistory.map((entry,index) => (
  <p key={index}><strong>{entry.sender}:</strong> {entry.message}</p> 
))}*/

export default App